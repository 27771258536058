import './App.css';
import Home from "./components/home/home";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import VerifyMail from "./components/verify_email/verify_mail";
import ThankYou from "./components/verify_email/thank_you";
import DEBUG from "./components/DEBUG/DEBUG";
import {getFormProperties} from "./components/form/formData";
import {FormProperties} from "./components/form/formInterfaces";
import FormActiveRedirect from "./components/form/formActiveRedirect";

function App() {
    return (
        <div className="app-container">
            <Header/>
            <BrowserRouter basename="/">
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    {getFormProperties().map((property: FormProperties) => (
                        <Route
                            key={property.path}
                            path={property.path}
                            element={<FormActiveRedirect {...property.formData} />}
                        />
                    ))}
                    <Route path="/verify-email" element={<VerifyMail/>}/>
                    <Route path="/thank-you" element={<ThankYou/>}/>
                    {process.env.NODE_ENV === 'development' && <Route path="/debug" element={<DEBUG/>}/>}
                </Routes>
            </BrowserRouter>
            <Footer/>
        </div>
    );
}

export default App;