import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

const Home: FunctionComponent = () => {
    return (
        <div>
            <Helmet>
                <title>Forms</title>
                <meta name="Forms home page" content="The home page for all Altitude forms"/>
            </Helmet>
            <header className="App-header">
                <h1>Welcome to the Altitude forms page</h1>
                <h2><a href="/contact">Contact us.</a></h2>
                <h2><a href="/apply">Apply for staff.</a></h2>
            </header>
        </div>
    );
}

export default Home;