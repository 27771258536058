import {FormData} from "../formInterfaces";
import * as Yup from "yup";

export const apply: FormData = {
    steps: [
        {
            label: "What is your Minecraft username?",
            name: "username",
            type: "text",
            min_length: 3,
            max_length: 16,
            required: true,
        },
        {
            label: "What is your email?",
            name: "email",
            type: "email",
            min_length: 3,
            max_length: 254,
            required: true,
        },
        {
            label: "What is your Discord username?",
            name: "discord",
            type: "text",
            min_length: 2,
            max_length: 32,
            required: true,
        },
        {
            label: "Do you meet the minimum pc requirements?",
            name: "pc_requirements",
            type: "dropdown",
            min_length: 2,
            max_length: 3,
            required: true,
            additional_info: "Use voice chat in Discord with reasonable audio quality\n" +
                "Take screenshots through your pc (normally f2 in minecraft)\n" +
                "Record your screen through your pc at 20fps+ and 720p+ at normal Minecraft settings (with free programs like OBS)\n",
            drop_down: ["Yes", "No", ""],
        },
        {
            label: "What is your age?",
            name: "age",
            type: "text",
            min_length: 1,
            max_length: 3,
            required: true,
        },
        {
            label: "What is your preferred pronoun?",
            name: "pronoun",
            type: "dropdown",
            min_length: 0,
            max_length: 16,
            required: true,
            drop_down: ["They/them", "She/her", "He/him", ""],
        },
        {
            label: "When did you join Altitude?",
            name: "join_date",
            type: "date",
            min_length: 4,
            max_length: 32,
            required: true,
        },
        {
            label: "On average, how many hours per week are you available to moderate Altitude?",
            name: "avg_time",
            type: "text",
            min_length: 1,
            max_length: 2,
            required: true,
        },
        {
            label: "Which days are you normally able to come on?",
            name: "available_days",
            type: "select",
            min_length: 6,
            max_length: 128,
            required: true,
            drop_down: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            multiple: true
        },
        {
            label: "What time of the day are you normally online?",
            name: "available_time",
            type: "textarea",
            min_length: 3,
            max_length: 256,
            required: true,
            additional_info: "Please include your timezone."
        },
        {
            label: "Do you have any previous experience being staff?",
            name: "staff_experience",
            type: "textarea",
            min_length: 2,
            max_length: 2000,
            required: true,
        },
        {
            label: "Which server plugins do you have experience with?",
            name: "plugin_experience",
            type: "textarea",
            min_length: 2,
            max_length: 2000,
            required: true,
        },
        {
            label: "Why are you interested in being a moderator on Altitude?",
            name: "why_staff",
            type: "textarea",
            min_length: 2,
            max_length: 2000,
            required: true,
        },
        {
            label: "What do you believe are the expectations of a moderator?",
            name: "expectations_mod",
            type: "textarea",
            min_length: 2,
            max_length: 2000,
            required: true,
        },
        {
            label: "You may share anything else you wish here.",
            name: "other",
            type: "textarea",
            min_length: 2,
            max_length: 2000,
            required: false,
        },
    ],
    backend: `${process.env.REACT_APP_BACKEND_BASE_URL}/api/apply/staffApplication`,
    userInput: {username: '', email: '', discord: '', pc_requirements: ''
    , age: '', pronoun: '', join_date: '', avg_time: '', available_days: '', available_time: '', staff_experience: ''
    , plugin_experience: '', why_staff: '', expectations_mod: '', other: ''
    },
    spec: Yup.object().shape({
        username: Yup.string()
            .min(3, 'Username should be at least 3 characters')
            .max(16, 'Username should not exceed 16 characters')
            .matches(/^[a-zA-Z0-9_]*$/, 'Username should only include alphanumeric characters and underscore')
            .required('Please enter your username'),

        email: Yup.string()
            .email('Invalid email')
            .min(3, 'Email should be at least 3 characters')
            .max(254, 'Email should not exceed 254 characters')
            .required('Please enter your email'),

        discord: Yup.string()
            .min(2, 'Discord name should be at least 2 characters')
            .max(32, 'Discord name should be at most 32 characters')
            .matches(/^(?!.*\.\.)([a-z0-9._]{2,32})$/, 'Please enter a valid Discord name')
            .required('Please enter your Discord name'),

        pc_requirements: Yup.string()
            // .min(2, "Please answer yes or no")
            // .max(3, "Please answer yes or no")
            .matches(/(yes|no)$/i, 'Yes or no')
            .required('An answer is required'),

        age: Yup.number()
            .typeError('Input must be a number')
            .integer('Please enter a whole number')
            .min(0, 'Please enter a valid age')
            .max(999, 'We do not accept players older than 999 years old sorry!')
            .required('You are required to fill out your age'),

        pronoun: Yup.string()
            .max(16, 'Pronouns can\'t be longer than 16 characters'),

        join_date: Yup.date()
            .required('Your join date is required, if you\'re not sure enter an estimated date'),

        avg_time: Yup.number()
            .typeError('Please enter a number')
            .integer('Please enter a whole number')
            .min(0, 'Please enter a positive number')
            .max(168, 'There are only 168 hours in a week')
            .required('Please enter the average time you will be available each week'),

        available_days: Yup.lazy(value =>
            Array.isArray(value)
                ? Yup.array().of(Yup.string()).min(1).max(7).required('Please select the days you will be available on')
                : Yup.string().required('Please select the days you will be available on')
        ),

        available_time: Yup.string()
            .min(3, 'Please provide at least 3 characters')
            .max(256, 'Please provide at most 256 characters')
            .required('Please enter your available time'),

        staff_experience: Yup.string()
            .min(2, 'Please provide your experience as staff, if you don\'t have any you can say that instead')
            .max(2000, 'Please provide at most 2000 characters')
            .required('Please enter your staff experience or simply say you don\'t have any'),

        plugin_experience: Yup.string()
                    .min(2, 'Please provide your experience with moderation plugins, if you don\'t have any you can say that instead')
                    .max(2000, 'Please provide at most 2000 characters')
                    .required('Please enter your plugin experience or simply say you don\'t have any'),

        why_staff: Yup.string()
                    .min(2, 'Please provide your reason for wanting to be a moderator')
                    .max(2000, 'Please provide at most 2000 characters')
                    .required('Please enter your reason for wanting to be a moderator on Altitude'),

        expectations_mod: Yup.string()
                    .min(2, 'Please provide your expectations of a moderator')
                    .max(2000, 'Please provide at most 2000 characters')
                    .required('Please enter your expectations of what a moderator does'),

        other: Yup.string()
                    .max(2000, 'Please provide at most 2000 characters')
    }),
    title: "Staff Application",
    backendFormName: "StaffApplication",
};