import {FormData} from "./formInterfaces";
import {useEffect, useState, useCallback} from "react";
import GenericForm from "./genericForm";

const FormActiveRedirect = (formData: FormData) => {
    const [isLoading, setLoading] = useState(true);
    const [isFormActive, setFormActive] = useState(false);

    const handleCheckForm = useCallback(async () => {
        const result = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/checks/formActive`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ formName: formData.backendFormName })
        });
        const response = await result.json();
        setFormActive(response.isActive);
        setLoading(false);
    }, [formData.backendFormName]);

    useEffect(() => {
        handleCheckForm().then(() => {});
    }, [handleCheckForm]);

    if (isLoading) {
        return <div className={"container"}><h2>Checking if form is active</h2></div>;
    }

    if (!isFormActive) {
        return <div className={"container"}><h2>The {formData.title} is not currently active</h2></div>;
    }

    return (
        <GenericForm {...formData} />
    );
}

export default FormActiveRedirect;