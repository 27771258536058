import React, {useState} from "react";
import './GenericForm.css';
import {Field} from "formik";
import {FormHandlerProps} from "./formInterfaces";
import ReactSelect from 'react-select';

const FormHTML: React.FC<FormHandlerProps> = ({
                                                  steps,
                                                  currentStep,
                                                  handleChange,
                                                  setFieldTouched,
                                                  setFieldValue,
                                                  values,
                                                  prevLength,
                                                  setPrevLength
                                              }) => {
    const currentField = steps[currentStep];
    let fieldOptions = currentField.drop_down && Array.isArray(currentField.drop_down)
        ? currentField.drop_down.map((value: string) => ({ value, label: value }))
        : [];
    const fieldValues = values[currentStep] ? values[currentStep].split(', ').map(value => ({ value, label: value })) : []

    const [selectedOptions, setSelectedOptions] = useState(fieldValues);

    if (currentField.type === 'select') {
        return (
            <ReactSelect
                name={currentField.name}
                isMulti={true}
                options={fieldOptions}
                value={selectedOptions}
                onChange={(selected) => {
                    let value: string = '';
                    if (selected === null) {
                        value = '';
                        setSelectedOptions([]);
                    } else if (Array.isArray(selected)) {
                        value = selected.map(s => s.value).join(', ');
                        setSelectedOptions(selected);
                    }

                    setFieldValue(currentField.name, value);

                    if (prevLength !== value.length) {
                        setFieldTouched(currentField.name);
                        setPrevLength(value.length);
                    }
                }}
            />
        );
    } else if (currentField.type === 'dropdown') {
        return (
            <Field as="select"
                   name={steps[currentStep].name}
                   {...(steps[currentStep].multiple && { multiple: true })}
                   onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                       handleChange(e);
                       if (prevLength !== values[steps[currentStep].name].length) {
                           setFieldTouched(steps[currentStep].name);
                           setPrevLength(values[steps[currentStep].name].length);
                       }
                   }}>
                {steps[currentStep].drop_down?.map((option, i) => (
                    <option key={i} value={option}>
                        {option}
                    </option>
                ))}
            </Field>
        );
    } else {
        return (
            <Field
                type={steps[currentStep].type}
                name={steps[currentStep].name}
                required={steps[currentStep].required}
                min={steps[currentStep].min_length}
                max={steps[currentStep].max_length}
                as={(steps[currentStep].type === "textarea") ? "textarea" : "input"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    if (prevLength !== values[steps[currentStep].name].length) {
                        setFieldTouched(steps[currentStep].name);
                        setPrevLength(values[steps[currentStep].name].length);
                    }
                }}
            />
        );
    }
};

export default FormHTML;