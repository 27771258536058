import React, { FC } from 'react';
import './Header.css';

const Header: FC = () => {

    return (
        <div></div>
    )
};

export default Header;