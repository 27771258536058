import React, { FC } from 'react';
import './Footer.css';

const Footer: FC = () => {
    const version: string = "1.20.4"; // replace with your version

    return (
        <footer>
            <div id="footer">
                <div id="footerinner">
                    <div id="footertext">
                        <h2>ABOUT US</h2>
                        <p>Altitude is a community-centered {version} survival server. We're one of those
                            servers you come to call "home". We are your place to get together with friends and play
                            survival, with a few extra features suggested by our community!</p>
                        <div className="followus">
                            <a target="_blank" rel="noreferrer" href="https://discordapp.com/invite/TGqpzCJ">
                                <img src="https://alttd.com//assets/img/logos/discord.png" alt="Discord Button"/>
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://twitter.com/alttdmc">
                                <img src="https://alttd.com//assets/img/logos/twitter.png" alt="Twitter Button"/>
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://instagram.com/alttdmc">
                                <img src="https://alttd.com/assets/img/logos/instagram.png" alt="Instagram Button"/>
                            </a>
                        </div>
                    </div>
                    <div className="footernav">
                        <h2>COMMUNITY</h2>
                        <ul>
                            <li><a target="_blank" rel="noreferrer"
                                   href="https://discordapp.com/invite/TGqpzCJ">Discord</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://alttd.com/blog">Blog</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://twitter.com/alttdmc">Twitter</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://instagram.com/alttdmc">Instagram</a>
                            </li>
                            <li><a target="_blank" rel="noreferrer" href="https://reddit.com/r/alttd">Reddit</a></li>
                        </ul>
                    </div>
                    <div className="footernav">
                        <h2>SERVER</h2>
                        <ul>
                            <li><a href="https://alttd.com/about">About Us</a></li>
                            <li><a href="https://alttd.com/team">Staffing Team</a></li>
                            <li><a href="https://alttd.com/policy">Privacy Policy</a></li>
                            <li><a href="https://alttd.com/terms">Terms of Use</a></li>
                        </ul>
                    </div>
                </div>
                <p id="copyright">Copyright © 2015-2023 Altitude. All rights Reserved. Not affiliated with Mojang AB or
                    Microsoft.</p>
            </div>
        </footer>
    )
};

export default Footer;