import {FC, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import './VerifyMail.css';
import {Helmet} from "react-helmet";

interface VerificationData {
    eMail: string;
    code: string;
}

const VerifyMail: FC = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const [code, setCode] = useState('000000');

    if (location.state === null || location.state.email === undefined) {
        return (
            <div className="container">
                <p>Are you in the right place? It doesn't look like you have an email to verify!</p>
            </div>
        )
    }
    const email = location.state.email;

    function setFormData(data: DynamicFormData) {
        console.log("Setting form data")
        navigate('/thank-you', {
            state: {
                formData: data
            }
        });
    }

    type DynamicFormData = {
        [key: string]: string;
    };


    const handleCodeSubmit = async () => {
        const verificationData: VerificationData = {
            code: code,
            eMail: email
        }
        console.log(verificationData);
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/verify_email/form`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(verificationData)
        })
            .then(response => {
                if (!response.ok) {
                    //TODO fix error and make message here?
                    console.log(response)
                    throw new Error('Invalid code');
                }
                //TODO check if its json if not its just text we need to handle and put in a p tag
                return response.json()
            })
            .then((data: DynamicFormData) => {
                console.log(data);
                setFormData(data);
            })
            .catch(error => {
                console.error('Received an unexpected error: ' + error);
            })
    }

    return (
        <div className="container">
            <Helmet>
                <title>Email validation</title>
            </Helmet>
            <header>Email validation</header>
            <div className="content">
                <p>Hi, you just completed a form and need to verify your email ({email}).</p>
                <p>Please check your email for a verification code and enter it below:</p>
                <input
                    type="text"
                    value={code}
                    onChange={(e) => {
                        if (/^\d{0,6}$/.test(e.target.value)) {
                            setCode(e.target.value);
                        }
                    }}
                    pattern="\d{6}"
                    maxLength={6}
                    className="verification-code-input"
                />
                <button onClick={handleCodeSubmit} className="submit-button">Submit Code</button>
            </div>
        </div>
    )
}

export default VerifyMail