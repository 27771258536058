import {FC} from "react";
import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import './ThankYou.css';

const ThankYou: FC = () => {
    const location = useLocation();
    type DynamicFormData = {
        [key: string]: any;
    };

    if (location.state === null || location.state.formData === undefined) {
        return (
            <div className="container">
                <p>Are you in the right place? It doesn't look like you completed a form or an email verification!</p>
            </div>
        )
    }

    const formData: DynamicFormData = location.state.formData;

    return (
        <div className="container">
            <Helmet>
                <title>Thank you!</title>
            </Helmet>
            <header className="header">Thank you for completing the form and verifying your email!<br></br>This is the data you entered:</header>
            <div className="fields">
                {Object.entries(formData).map(([key, value]) => (
                    <div className={"field"}>
                        <p><strong>{key}</strong></p>
                        {
                            typeof value === "string" && value.includes("\n") ?
                                    value
                                        .split("\n")
                                        .filter(str => str.trim() !== "")
                                        .map((str, index) => <p style={{ wordWrap: 'break-word' }} key={index}>{str}</p>)
                                    : <p style={{ wordWrap: 'break-word' }}>{value}</p>
                        }
                    </div>
                ))}
            </div>
    </div>
)
    ;
}


export default ThankYou;