import {FormData} from "../formInterfaces";
import * as Yup from "yup";

export const contact: FormData = {
    steps: [
        {
            label: "What is your Minecraft username?",
            name: "username",
            type: "text",
            min_length: 3,
            max_length: 16,
            required: true,
        },
        {
            label: "What is your email?",
            name: "email",
            type: "email",
            min_length: 3,
            max_length: 254,
            required: true,
        },
        {
            label: "What is your question?",
            name: "question",
            type: "textarea",
            min_length: 10,
            max_length: 2000,
            required: true,
        },
    ],
    backend: `${process.env.REACT_APP_BACKEND_BASE_URL}/api/contact/submitContactForm`,
    userInput: {username: '', email: '', question: ''},
    spec: Yup.object().shape({
        username: Yup.string()
            .min(3, 'Username should be at least 3 characters')
            .max(16, 'Username should not exceed 16 characters')
            .matches(/^[a-zA-Z0-9_]*$/, 'Username should only include alphanumeric characters and underscore')
            .required('Username is required'),

        email: Yup.string()
            .email('Invalid email')
            .min(3, 'Email should be at least 3 characters')
            .max(254, 'Email should not exceed 254 characters')
            .required(),

        question: Yup.string()
            .min(10, 'Question should be at least 10 characters')
            .max(2000, 'Question should not exceed 2000 characters')
            .required('Question is required')
    }),
    title: "Contact Form",
    backendFormName: "ContactForm",
};